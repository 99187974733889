// extracted by mini-css-extract-plugin
export var button = "registration-page-module--button--zpWsa";
export var cASLOGOIcon = "registration-page-module--cASLOGOIcon--Tmp6q";
export var canadianSupply = "registration-page-module--canadianSupply--fnfkU";
export var ccs = "registration-page-module--ccs--6AFoL";
export var contactDetailsIcon = "registration-page-module--contactDetailsIcon--KAKGf";
export var contactForm = "registration-page-module--contactForm--0toQO";
export var contactFormInputs = "registration-page-module--contactFormInputs--t-w6B";
export var display = "registration-page-module--display--6-4XC";
export var footer = "registration-page-module--footer--MafJa";
export var footerContent = "registration-page-module--footerContent--9NlKr";
export var footerL = "registration-page-module--footerL--SEKfM";
export var footerLinks = "registration-page-module--footerLinks--Zl6-O";
export var footerR = "registration-page-module--footerR--iE19M";
export var formTitle = "registration-page-module--formTitle--0Uz7h";
export var fullName = "registration-page-module--fullName--lYB4i";
export var input = "registration-page-module--input--wGktM";
export var input6 = "registration-page-module--input6--G4h6g";
export var inputFieldWIthLabel = "registration-page-module--inputFieldWIthLabel--adtUS";
export var inputFieldWIthLabel9 = "registration-page-module--inputFieldWIthLabel9--blRxw";
export var inputLabel = "registration-page-module--inputLabel--j96Bw";
export var inputLabel9 = "registration-page-module--inputLabel9--saT6f";
export var inputRow = "registration-page-module--inputRow--aiBHq";
export var inputRow4 = "registration-page-module--inputRow4--ZCMGq";
export var logo = "registration-page-module--logo--ibQjv";
export var passwordsShouldContainAMin = "registration-page-module--passwordsShouldContainAMin--N-Kbm";
export var privacyPolicy = "registration-page-module--privacyPolicy--SmP7T";
export var registerContent = "registration-page-module--registerContent--6Bxsf";
export var registerSection = "registration-page-module--registerSection--5UIpE";
export var registrationPage = "registration-page-module--registrationPage--52LBt";
export var shippingPolicy = "registration-page-module--shippingPolicy--o9Dsu";
export var visaIcon = "registration-page-module--visaIcon--pXZcn";