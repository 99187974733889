import * as React from "react";

import Layout from "../components/Base/Layout";
import Registration from "../components/Register/registration-page"
import UnAuthContent from "../components/Login/UnAuthContent";
import { SEO } from "../components/Base/Seo";

export default function Register() {
  
  return (
    <Layout>
      <UnAuthContent>
        <Registration />
      </UnAuthContent>
    </Layout>
  );
}

export const Head = () => (
  <SEO title="Canadian Supply | Registration" description="Shop" />
)